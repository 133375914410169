import { post } from '@api/api';

export type PostLoginUser = {
  email: string;
  password: string;
};

export type PostLoginApiReturn = {
  token: string;
};

export async function postLoginUser(user: PostLoginUser) {
  // Do not redirect if the login fails, a 401 error just means they failed to login.
  return post<PostLoginApiReturn>('/login', user, {
    redirectOnAuthError: false,
  });
}
