export const staffRoles: string[] = ['Admin'];

export interface IStaffUser {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  isActive: boolean;
  role: string;
}

export type IStaffUserNoPassword = Omit<IStaffUser, 'password'>;
