import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from '@mui/material';
import { BulkUsersQueryType, createBulkUsers } from '@api/user/createUser';
import { useLazyQuery } from '@api/useQuery';
import { readExcelFile, validateParsedData, ParsedUserType } from './utils';
import UploadSection from './UploadSection';
import UsersGridSection from './UsersGridSection';
import ErrorDisplay, { RowError } from './ErrorDisplay';
import { IUser } from '@api/user/types/IUser';

interface BulkUsersFormProps {
  open: boolean;
  onClose: () => void;
  organizationId: string | undefined;
  runGetAllUsersQuery: () => Promise<{
    data: IUser[] | null;
    statusCode: number | null;
    error: Error | null;
  }>;
  setSuccessSnackbarMessage: (message: string) => void;
  setErrorSnackbarMessage: (message: string) => void;
  errorSnackbarMessage: string | undefined;
}

const BulkUsersForm: React.FC<BulkUsersFormProps> = ({
  open,
  onClose,
  organizationId,
  runGetAllUsersQuery,
  setSuccessSnackbarMessage,
  setErrorSnackbarMessage,
  errorSnackbarMessage,
}) => {
  const [currentFileName, setCurrentFileName] =
    useState<string>('No file selected');
  const [parsedUsers, setParsedUsers] = useState<ParsedUserType[]>([]);
  const [errors, setErrors] = useState<Array<RowError>>([]);
  const hasParsedUsers = parsedUsers.length > 0;

  const { runQuery: runBulkUsersQuery } = useLazyQuery(
    (bulkUsersToSave: ParsedUserType[]) => {
      const organizationIdNumber = Number(organizationId);
      const queryData: BulkUsersQueryType = {
        users: bulkUsersToSave,
        organizationId: organizationIdNumber,
      };
      return createBulkUsers(queryData);
    },
    {
      onSuccess: () => {
        setSuccessSnackbarMessage &&
          setSuccessSnackbarMessage('Users created successfully!');
        runGetAllUsersQuery();
        onClose();
        setParsedUsers([]);
      },
      onError: (error) => {
        setErrorSnackbarMessage(`${error}`);
      },
    }
  );

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const data = await readExcelFile(file);
      const validationErrors = validateParsedData(data);
      if (validationErrors.length === 0) {
        setParsedUsers(data);
        setErrors([]);
      } else {
        setErrors(
          validationErrors.filter((error) => error !== null) as RowError[]
        );
      }
      setCurrentFileName(file.name);
    }
  };

  const resetForm = (closeModal: boolean | null) => {
    setParsedUsers([]);
    setCurrentFileName('No file selected');
    setErrorSnackbarMessage('');
    setErrors([]);
    if (closeModal === true) {
      onClose();
    }
  };

  const onSubmit = () => {
    runBulkUsersQuery(parsedUsers);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth={hasParsedUsers ? 'xl' : false}
    >
      <DialogTitle>Bulk Create Users</DialogTitle>
      <DialogContent dividers>
        {errors.length > 0 && <ErrorDisplay errors={errors} />}
        {!hasParsedUsers ? (
          <UploadSection handleFileChange={handleFileChange} />
        ) : (
          <UsersGridSection
            parsedUsers={parsedUsers}
            currentFileName={currentFileName}
            errorSnackbarMessage={errorSnackbarMessage}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => (hasParsedUsers ? resetForm(false) : resetForm(true))}
        >
          {hasParsedUsers ? 'Clear' : 'Close'}
        </Button>
        <Button
          variant="contained"
          disabled={!hasParsedUsers}
          onClick={onSubmit}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BulkUsersForm;
