export interface IUser {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  status: boolean;
  access: string;
  organizationId: string;
  flags: string[];
  phoneNumber: string | null;
}

export const userRoles: string[] = [
  'ADMIN',
  'HEALTHSYSTEMADMIN',
  'CAREGIVER',
  'GOD',
  'PIPELINEGOD',
  'TRACKINGADMIN',
];
