import { useState } from 'react';
import {
  GetOrganizationsApiReturn,
  getOrganizations,
} from '@api/organizations/getOrganizations';
import { useQuery } from '@api/useQuery';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { Button, CircularProgress, Chip, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { CreateEditOrganizationModal } from 'pages/Organizations/OrganizationForm';
import { useNavigate } from 'react-router-dom';

export const OrganizationsListView = () => {
  const {
    data,
    loading,
    error,
    runQuery: fetchOrganizationList,
  } = useQuery(() => getOrganizations());

  const [editOrg, setEditOrg] = useState<GetOrganizationsApiReturn | null>(
    null
  );
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleOpen = () => {
    setEditOrg(null);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (error) {
    return <Typography color="red">{error.toString()}</Typography>;
  }

  if (loading || !data) {
    return <CircularProgress />;
  }

  const columns: GridColDef<GetOrganizationsApiReturn>[] = [
    { field: 'id', headerName: 'ID', type: 'number', width: 90 },
    {
      field: 'name',
      headerName: 'Name',
      width: 200,
    },
    {
      field: 'ehr',
      headerName: 'EMR',
      width: 100,
      filterable: true,
    },
    {
      field: 'active',
      headerName: 'Active',
      type: 'boolean',
      width: 100,
    },
    {
      field: 'flags',
      headerName: 'flags',
      flex: 1,
      renderCell: (params) => {
        const flags = params.row.flags;
        return (
          <div style={{ display: 'flex', gap: 5 }}>
            {flags &&
              flags.map((flag, index: number) => (
                <Chip key={index} label={flag} variant="outlined" />
              ))}
          </div>
        );
      },
    },
    {
      field: 'edit',
      headerName: '',
      width: 100,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <Button
          onClick={() => {
            setEditOrg(params.row);
            setOpen(true);
          }}
          startIcon={<EditIcon />}
          className="editButton"
        >
          Edit
        </Button>
      ),
    },
    {
      field: 'viewOrganization',
      headerName: '',
      width: 150,
      renderCell: (params) => {
        const { id } = params;
        return (
          <div style={{ display: 'flex', gap: 5 }}>
            <Button onClick={() => navigate(`/organizations/${id}/users`)}>
              Manage Users
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <CreateEditOrganizationModal
        key={open.toString()}
        open={open}
        onClose={handleClose}
        editOrg={editOrg}
        fetchOrganizationList={fetchOrganizationList}
      />
      <div style={styles.header}>
        <h1 className="listViewHeaderText">Organizations</h1>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleOpen}
          className="createButton"
        >
          Create
        </Button>
      </div>
      <DataGrid
        rows={data}
        columns={columns}
        initialState={{
          sorting: {
            sortModel: [
              {
                field: 'id',
                sort: 'asc',
              },
            ],
          },
        }}
        sx={{ backgroundColor: 'white' }}
      />
    </div>
  );
};

const styles = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
} satisfies InlineCss;
