import { IFeatureFlagsUser } from '@api/featureFlags/types/IFeatureFlags';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

const userColumns: GridColDef<IFeatureFlagsUser>[] = [
  {
    field: 'id',
    headerName: 'ID',
    type: 'number',
    width: 75,
    headerClassName: 'dataGridHeader',
  },
  {
    field: 'firstName',
    headerName: 'First Name',
    flex: 1,
    headerClassName: 'dataGridHeader',
  },
  {
    field: 'lastName',
    headerName: 'Last Name',
    flex: 1,
    headerClassName: 'dataGridHeader',
  },
];

interface IUserTabPanelProps {
  users: IFeatureFlagsUser[];
}

export function UserTabPanel(props: IUserTabPanelProps) {
  const { users } = props;
  return (
    <DataGrid
      rows={users}
      columns={userColumns}
      initialState={{
        sorting: {
          sortModel: [{ field: 'id', sort: 'asc' }],
        },
        pagination: { paginationModel: { pageSize: 10 } },
      }}
      sx={{
        backgroundColor: 'white',
      }}
      autoHeight
      pageSizeOptions={[10, 25, 50]}
    />
  );
}
