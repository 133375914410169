import * as XLSX from 'xlsx';
import { z } from 'zod';

export type ParsedUserType = {
  [key: string]: string | number | undefined;
  'First Name (Required)': string;
  'Last Name (Required)': string;
  'Access: User or Admin (Required)': 'User' | 'Admin';
  'Email address (Required)': string;
  'Job Type (optional)': string | undefined;
  'Phone number (optional)': number | undefined;
};

const userSchema = z.object({
  'First Name (Required)': z.string().min(1, 'First name is required'),
  'Last Name (Required)': z.string().min(1, 'Last name is required'),
  'Access: User or Admin (Required)': z
    .enum(['User', 'Admin'])
    .refine((val) => ['User', 'Admin'].includes(val), {
      message: 'Access must be either User or Admin',
    }),
  'Email address (Required)': z.string().email('Invalid email address'),
  'Job Type (optional)': z.string().optional(),
  'Phone number (optional)': z.number().optional(),
});

export const readExcelFile = async (file: File): Promise<ParsedUserType[]> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      try {
        if (e.target && e.target.result) {
          const binaryStr = e.target.result as string;
          const workbook = XLSX.read(binaryStr, { type: 'binary' });
          const sheet = workbook.Sheets[workbook.SheetNames[0]];
          const data = XLSX.utils.sheet_to_json(sheet, {
            header: 1,
            defval: '',
          });
          const headerRow = data[0] as string[];
          const parsedData = data.slice(1).map((row: any) => {
            const parsedRow: ParsedUserType = {} as ParsedUserType;

            headerRow.forEach((key: string, index: number) => {
              parsedRow[key] = row[index] || undefined;
            });
            return parsedRow;
          });
          resolve(parsedData);
        } else {
          reject(new Error('No data to read from file'));
        }
      } catch (error) {
        reject(error);
      }
    };
    reader.readAsBinaryString(file);
  });
};

export const validateParsedData = (data: ParsedUserType[]) => {
  return data
    .map((item, index) => {
      try {
        userSchema.parse(item);
        return null; // No error for this item
      } catch (error) {
        if (error instanceof z.ZodError) {
          return { row: index + 1, errors: error.issues };
        }
        return { row: index + 1, errors: [{ message: 'Unknown error' }] };
      }
    })
    .filter((item) => item !== null); // Filter out the nulls (valid items)
};
