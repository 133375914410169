import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {
  Divider,
  Drawer,
  List,
  ListItem,
  SxProps,
  Toolbar,
} from '@mui/material';
import { NavHeader } from '@components/nav/NavHeader';
import { appBarHeight, drawerWidth } from '@components/nav/constants';
import { useNavigate } from 'react-router-dom';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import HomeIcon from '@mui/icons-material/Home';
import FlagIcon from '@mui/icons-material/Flag';
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';

export const LeftNav = () => {
  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Toolbar disableGutters>
        <div
          style={{
            height: appBarHeight,
            display: 'flex',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <NavHeader />
        </div>
      </Toolbar>
      <Divider />
      <List>
        <NavItem
          text="Organizations"
          url="/organizations"
          icon={<HomeIcon sx={{ color: '#7070bd' }} />}
        />
        <Divider />

        <NavItem
          text="Feature Flags"
          url="/feature-flags"
          icon={<FlagIcon sx={{ color: '#b98a35' }} />}
        />
        <Divider />

        <NavItem
          text="Staff Users"
          url="/staff-users"
          icon={<AdminPanelSettingsIcon sx={{ color: '#a50000' }} />}
        />
        <Divider />

        <NavItem
          text="Change Logs"
          url="/audit-logs"
          icon={<ChangeHistoryIcon sx={{ color: '#3cba42' }} />}
        />
        <Divider />
      </List>
    </Drawer>
  );
};

type NavItemProps = {
  text: string;
  icon: React.ReactNode;
  url: string;
};

/**
 * A very basic nav item that just renders an anchor tag with a text and icon.
 *
 * This currently cannot handling navigating to other sites as it only uses react-router navigation.
 */
const NavItem = (props: NavItemProps) => {
  const { url, text, icon } = props;

  const navigate = useNavigate();

  const goToLink = (event: React.MouseEvent) => {
    navigate(url);
    event.preventDefault();
  };

  const isSelected = window.location.pathname === url;

  return (
    <ListItem key={text} disablePadding>
      <ListItemButton
        onClick={goToLink}
        href={url}
        selected={isSelected}
        sx={listItemStyle}
      >
        <ListItemIcon sx={{ minWidth: '40px' }}>{icon}</ListItemIcon>
        <ListItemText primary={text} />
      </ListItemButton>
    </ListItem>
  );
};

// Splitting the style out incase we need other kinds of nav items.
const listItemStyle: SxProps = {
  '&.Mui-selected': {
    backgroundColor: '#e6f7ff',
    borderRight: '2px solid #1890ff',
    color: '#1890ff',
  },
  '&:hover': {
    backgroundColor: '#e6f7ff',
    color: '#1890ff',
  },
};
