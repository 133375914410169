import { ErrorSnackbar } from '@components/forms/snackbars/ErrorSnackbar';
import { SuccessSnackbar } from '@components/forms/snackbars/SuccessSnackbar';
import { StaffAvatar } from '@components/nav/avatar/StaffAvatar';
import { appBarHeight, drawerWidth } from '@components/nav/constants';
import {
  AppBar,
  ButtonBase,
  Divider,
  Menu,
  MenuItem,
  SxProps,
  Toolbar,
} from '@mui/material';
import { ChangePasswordForm } from 'pages/ChangePassword/ChangePasswordForm';
import { useAuthContext } from 'pages/Login/useAuthContext';
import { useRef, useState } from 'react';

type TopNavProps = {
  sx?: SxProps;
};

/**
 * The nav bar at the top of the page with the user's avatar in it.
 */
export const TopNav = (props: TopNavProps) => {
  const { sx } = props;

  const { logoutUser } = useAuthContext();

  const anchorEl = useRef(null);
  const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);
  const [isChangePasswordOpen, setIsChangePasswordOpen] = useState(false);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);

  const onClickAvatar = () => {
    setIsProfileDropdownOpen(true);
  };
  const onCloseProfileDropdown = () => {
    setIsProfileDropdownOpen(false);
  };

  const onClickLogout = () => {
    logoutUser();
  };

  const onClickChangePassword = () => {
    setIsChangePasswordOpen(true);
  };

  return (
    <>
      <ChangePasswordForm
        isOpen={isChangePasswordOpen}
        onClose={() => setIsChangePasswordOpen(false)}
        setOpenSuccessSnackbar={setOpenSuccessSnackbar}
        setOpenErrorSnackbar={setOpenErrorSnackbar}
      />
      <SuccessSnackbar
        isOpen={openSuccessSnackbar}
        onClose={() => setOpenSuccessSnackbar(false)}
        message="Password changed successfully!"
      />
      <ErrorSnackbar
        isOpen={openErrorSnackbar}
        onClose={() => setOpenErrorSnackbar(false)}
        message="Error changing password. Please try again."
      />
      <AppBar
        color="transparent"
        position="fixed"
        sx={{
          backgroundColor: 'white',
          width: `calc(100% - ${drawerWidth}px)`,
          marginLeft: `${drawerWidth}px`,
          boxShadow: 'none',
          ...sx,
        }}
      >
        <Toolbar>
          <div
            style={{
              width: '100%',
              height: appBarHeight,
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <ButtonBase onClick={onClickAvatar} ref={anchorEl}>
              <StaffAvatar />
            </ButtonBase>

            <Menu
              anchorEl={anchorEl?.current}
              open={isProfileDropdownOpen}
              onClose={onCloseProfileDropdown}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={onClickChangePassword}>
                Change Password
              </MenuItem>
              <MenuItem onClick={onClickLogout}>Logout</MenuItem>
            </Menu>
          </div>
        </Toolbar>
        <Divider />
      </AppBar>
    </>
  );
};
