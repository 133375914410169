import { post } from '@api/api';
import { IStaffUserNoPassword } from '@api/user/types/IStaff';

export interface ICreateStaffUserApi {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  role: string;
  isActive: boolean;
}

export function createStaffUser(toCreate: ICreateStaffUserApi) {
  return post<IStaffUserNoPassword>('/staff', toCreate);
}
