import { clearSession } from '@session/clearSession';
import { getJwt } from '@session/getJwt';
import { isUserLoggedIn } from '@session/isUserLoggedIn';
import { isEmpty, trimEnd, trimStart } from 'lodash';

function getAuthHeaders() {
  return {
    // Send the JWT if the user is logged in.
    'x-auth-token': isUserLoggedIn() ? `Bearer ${getJwt()}` : '',
    'Content-Type': 'application/json',
  };
}

// Fetch doesn't support generics to make `response.json()` strongly typed.
// Make a slightly different TypeScript type that supports this.
export type FetchResponse<TReturn> = Omit<Response, 'json'> & {
  json: () => Promise<TReturn>;
};

type ApiOptions = {
  /**
   * True if a 401 error should redirect the user to the login page, false to just return the result on a 401 like normal
   * Defaults to true.
   */
  redirectOnAuthError?: boolean;
};

async function fetchFromAPI<TReturn>(
  url: string,
  fetchOptions?: RequestInit,
  apiOptions: ApiOptions = {}
): Promise<FetchResponse<TReturn>> {
  const { redirectOnAuthError = true } = apiOptions;

  let baseUrl = import.meta.env.VITE_API_BASE_URL;

  if (isEmpty(baseUrl)) {
    throw new Error('Missing VITE_API_BASE_URL environment variable.');
  }

  baseUrl = trimEnd(baseUrl, '/');

  url = trimStart(url, '/');

  const fullUrl = encodeURI(`${baseUrl}/${url}`);

  const result = await fetch(fullUrl, fetchOptions);
  if (redirectOnAuthError) {
    if (result.status === 403 || result.status === 401) {
      // This means the token has expired. Kick them out!
      clearSession();
      window.location.href = '/';
    }
  }
  return result;
}

export function get<TReturn>(url: string, apiOptions?: ApiOptions) {
  return fetchFromAPI<TReturn>(
    url,
    {
      headers: getAuthHeaders(),
      method: 'GET',
    },
    apiOptions
  );
}

export function post<TReturn, TBody extends object = object>(
  url: string,
  body: TBody,
  apiOptions?: ApiOptions
) {
  return fetchFromAPI<TReturn>(
    url,
    {
      headers: getAuthHeaders(),
      method: 'POST',
      body: JSON.stringify(body),
    },
    apiOptions
  );
}

export function put<TReturn, TBody extends object = object>(
  url: string,
  body: TBody,
  apiOptions?: ApiOptions
) {
  return fetchFromAPI<TReturn>(
    url,
    {
      headers: getAuthHeaders(),
      method: 'PUT',
      body: JSON.stringify(body),
    },
    apiOptions
  );
}

export function deleteAPI<TReturn, TBody extends object = object>(
  url: string,
  body?: TBody,
  apiOptions?: ApiOptions
) {
  return fetchFromAPI<TReturn>(
    url,
    {
      headers: getAuthHeaders(),
      method: 'DELETE',
      body: JSON.stringify(body ?? {}),
    },
    apiOptions
  );
}
