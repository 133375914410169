import {
  IUpdatePasswordApi,
  updatePassword,
} from '@api/changePassword/updatePassword';
import { useLazyQuery } from '@api/useQuery';
import { FormErrorMessage } from '@components/forms/FormErrorMessage';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { ChangePasswordText } from 'pages/ChangePassword/ChangePasswordInputs/ChangePasswordText';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

// Interface for the form input
interface IFormInput {
  password: string;
  newPassword: string;
  confirmPassword: string;
}

// Regex for password validation
const passwordRegex =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

const passwordErrorMessages = {
  length: 'must be at least 8 characters long.',
  strength:
    'must contain at least one uppercase letter, one lowercase letter, one number, and one special character.',
};

// Form validation schema using Zod
const changePasswordValidator = z.object({
  password: z.string(),
  newPassword: z
    .string()
    .min(8, `New password ${passwordErrorMessages.length}`)
    .regex(passwordRegex, `New password ${passwordErrorMessages.strength}`),
  confirmPassword: z
    .string()
    .min(8, `Confirm password ${passwordErrorMessages.length}`)
    .regex(passwordRegex, `Confirm password ${passwordErrorMessages.strength}`),
});

export interface IChangePasswordFormProps {
  isOpen: boolean;
  onClose: () => void;
  setOpenSuccessSnackbar: (open: boolean) => void;
  setOpenErrorSnackbar: (open: boolean) => void;
}

export function ChangePasswordForm(props: IChangePasswordFormProps) {
  const { isOpen, onClose, setOpenSuccessSnackbar, setOpenErrorSnackbar } =
    props;

  /**
   * Default values for the form input
   */
  const defaultFormValues = {
    password: '',
    newPassword: '',
    confirmPassword: '',
  };

  // Form methods and states
  const methods = useForm<IFormInput>({
    defaultValues: defaultFormValues,
    resolver: zodResolver(changePasswordValidator),
  });
  const { handleSubmit, reset, control } = methods;
  const [formError, setFormError] = useState<boolean>(false);
  const [formErrorMessage, setFormErrorMessage] = useState<string>('');

  /**
   * Function to run the query to update the password
   * @param updatedPasswordToSave JSON object containing the fields necessary to update the password
   */
  const { runQuery: runChangePasswordQuery } = useLazyQuery(
    (updatedPasswordToSave: IUpdatePasswordApi) => {
      return updatePassword(updatedPasswordToSave);
    },
    {
      onSuccess: () => {
        setFormError(false);
        setFormErrorMessage('');
        setOpenSuccessSnackbar(true);
        handleClose();
      },
      onError: (error) => {
        setFormError(true);
        setFormErrorMessage(error.message);
        setOpenErrorSnackbar(true);
      },
    }
  );

  /**
   * Function to handle submitting the form
   * @param data Form data to submit
   */
  const onSubmit = (data: IFormInput) => {
    const updatedPasswordToSave = {
      password: data.password,
      newPassword: data.newPassword,
      confirmPassword: data.confirmPassword,
    };
    runChangePasswordQuery(updatedPasswordToSave);
  };

  /**
   * Function to handle closing the dialog and resetting the form
   */
  const handleClose = () => {
    setFormErrorMessage('');
    reset(defaultFormValues);
    onClose();
  };

  return (
    <>
      <Dialog fullWidth open={isOpen} onClose={handleClose}>
        <DialogTitle>Change Password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please fill out the following information to change your password.
          </DialogContentText>
          {
            // If there is an error creating the user, display the error message
            formError && formErrorMessage && (
              <FormErrorMessage error={'Error: ' + formErrorMessage} />
            )
          }
          <ChangePasswordText
            name="password"
            control={control}
            label="Old Password"
            styles={{ marginTop: '20px' }}
          />
          <ChangePasswordText
            name="newPassword"
            control={control}
            label="New Password"
            styles={{ marginTop: '20px' }}
          />
          <ChangePasswordText
            name="confirmPassword"
            control={control}
            label="Confirm New Password"
            styles={{ marginTop: '20px' }}
          />
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '20px',
            padding: '0px 20px 15px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '10px',
            }}
          >
            <Button onClick={handleClose} variant="outlined">
              Cancel
            </Button>

            <Button
              onClick={() => {
                reset(defaultFormValues);
              }}
              variant="outlined"
            >
              Reset
            </Button>
          </Box>
          <Button onClick={handleSubmit(onSubmit)} variant={'contained'}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
