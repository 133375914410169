import { useMemo, useState } from 'react';
import { getAuditLogs } from '@api/auditLogs/getAuditLogs';
import { useQuery } from '@api/useQuery';
import { CircularProgress, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

export const AuditLogView = () => {
  const { data, loading, error } = useQuery(getAuditLogs);

  const [rowsExpanded, setRowsExpanded] = useState<Record<string, boolean>>({});

  const auditLogFormatted = useMemo(() => {
    if (data == null) return [];

    const logsFormatted = data.logs.map((log) => {
      return {
        ...log,
        staffEmail: log.staffUser.email,
        dataFormatted: JSON.stringify(log.data, undefined, 2),
      };
    });

    return logsFormatted;
  }, [data]);

  type AuditLogFormatted = (typeof auditLogFormatted)[number];

  if (error) {
    return <Typography color="red">{error.toString()}</Typography>;
  }

  if (loading || !data) {
    return <CircularProgress />;
  }

  const columns: GridColDef<AuditLogFormatted>[] = [
    {
      field: 'createdAt',
      headerName: 'Date',
      type: 'dateTime',
      width: 170,
      valueGetter: ({ value }) => value && new Date(value),
      renderCell: (params) => {
        const createdAt = params.row.createdAt;
        return new Date(createdAt).toLocaleString();
      },
    },
    {
      field: 'event',
      headerName: 'Event',
      width: 160,
    },
    {
      field: 'recordType',
      headerName: 'Record Type',
      width: 120,
    },
    {
      field: 'recordId',
      headerName: 'ID',
      width: 80,
    },
    {
      field: 'staffEmail',
      headerName: 'Changed By User',
      width: 200,
      renderCell: (params) => {
        const staffUser = params.row.staffUser;
        return `${staffUser.email}`;
      },
    },
    {
      field: 'data',
      headerName: 'Data',
      flex: 1,
      renderCell: (params) => {
        const data = params.row.data;

        let formattedJson = JSON.stringify(data, undefined, 2);

        // Remove the opening and closing curly braces
        formattedJson = formattedJson.replace('{\n', '').replace('\n}', '');
        // Remove the extra 2 spaces before each key
        formattedJson = formattedJson
          .replace(/^ {2}/, '')
          .replace(/\n {2}/g, '\n');
        // Remove the quotes around the keys
        formattedJson = formattedJson.replace(/"(\w+)":/g, '$1:');

        return (
          <div style={{ width: '100%', overflowX: 'auto' }}>
            <pre style={{ margin: 0 }}>{formattedJson}</pre>
          </div>
        );
      },
    },
  ];

  return (
    <div style={{ width: '100%', maxWidth: '100%' }}>
      <div style={styles.header}>
        <h1 style={{ marginBottom: 0 }} className="listViewHeaderText">
          Change Logs
        </h1>
        <div>Click Row to Expand</div>
      </div>
      <DataGrid
        rows={auditLogFormatted}
        columns={columns}
        getRowHeight={(params) => {
          const row = params.model as AuditLogFormatted;

          if (rowsExpanded[row.id] === true) return 'auto';

          const jsonSplit = row.dataFormatted.split('\n');
          if (jsonSplit.length <= 6) return 'auto';
          return 95;
        }}
        onRowClick={(params) => {
          const row = params.row as AuditLogFormatted;
          setRowsExpanded((prev) => ({ ...prev, [row.id]: !prev[row.id] }));
        }}
        initialState={{
          sorting: {
            sortModel: [
              {
                field: 'createdAt',
                sort: 'desc',
              },
            ],
          },
        }}
        sx={{
          backgroundColor: 'white',
          maxWidth: '99%',
          width: '99%',
          '& .MuiDataGrid-cell': {
            fontSize: '13px',
            alignItems: 'flex-start',
            py: '12px',
          },
          '& .MuiDataGrid-overlayWrapper': {
            height: 50,
          },
        }}
      />
    </div>
  );
};

const styles = {
  header: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 10,
  },
} satisfies InlineCss;
