import { get } from '@api/api';

export type IGetAuditLogsApiReturn = {
  logs: {
    id: number;
    event: string;
    recordType: string;
    recordId: number;
    data: object;
    createdAt: string;
    staffUser: {
      id: number;
      email: string;
      firstName: string;
      lastName: string;
    };
  }[];
};

export type IAuditLog = IGetAuditLogsApiReturn['logs'][0];

export async function getAuditLogs() {
  return get<IGetAuditLogsApiReturn>('/audit-logs');
}
