import { useState } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useQuery } from '@api/useQuery';
import { getUsersByOrg } from '@api/user/getUsersByOrg';
import {
  Badge,
  Button,
  Chip,
  CircularProgress,
  Container,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { UserForm } from './UserForm';
import { capitalize } from 'lodash';
import { IUser } from '@api/user/types/IUser';
import { ErrorSnackbar } from '@components/forms/snackbars/ErrorSnackbar';
import { SuccessSnackbar } from '@components/forms/snackbars/SuccessSnackbar';
import { useParams } from 'react-router-dom';
import BulkUsersForm from 'pages/Users/BulkUsersForm';

/**
 * Formats the role string to have only the first letter capitalized
 * @param role string indicating the user's role
 * @returns string with only the first letter capitalized
 */
const formatRole = (role: string) => {
  if (role === 'HEALTHSYSTEMADMIN') return 'Health System Admin';
  switch (role) {
    case 'HEALTHSYSTEMADMIN':
      return 'Health System Admin';
      break;
    case 'PIPELINEGOD':
      return 'Pipeline God';
      break;
    case 'TRACKINGADMIN':
      return 'Tracking Admin';
      break;
  }
  return capitalize(role);
};

export function UsersListView() {
  const [openUserForm, setOpenUserForm] = useState(false);
  const [openBulkUserForm, setOpenBulkUserForm] = useState(false);
  const [editUser, setEditUser] = useState<IUser | null>(null);
  // State for the snackbar
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [successSnackbarMessage, setSuccessSnackbarMessage] = useState('');
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [errorSnackbarMessage, setErrorSnackbarMessage] = useState('');

  const { id } = useParams();

  // Queries
  const { data, runQuery, loading, error } = useQuery(() => getUsersByOrg(id!));

  /**
   * Opens the create staff user form
   */
  const createUser = () => {
    setOpenUserForm(true);
  };

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));
  // Define the columns for the table
  const columns: GridColDef<IUser>[] = [
    {
      field: 'edit',
      headerName: '',
      width: 10,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <Button
          onClick={() => {
            setEditUser(params.row);
            setOpenUserForm(true);
          }}
          startIcon={<EditIcon />}
          className="editButton"
        ></Button>
      ),
    },
    // {
    //   field: 'id',
    //   headerName: 'ID',
    //   type: 'number',
    //   width: 75,
    // },

    {
      field: 'firstName',
      headerName: 'First Name',
      type: 'string',
      width: 100,
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      type: 'string',
      width: 100,
    },
    {
      field: 'status',
      headerName: 'Status',
      type: 'boolean',
      width: 75,
    },
    {
      field: 'jobType',
      headerName: 'Job Type',
      type: 'string',
      width: 150,
    },
    {
      field: 'email',
      headerName: 'Email',
      type: 'string',
      width: 200,
    },
    {
      field: 'access',
      headerName: 'Access',
      type: 'string',
      width: 200,
      valueFormatter: (params) => formatRole(params.value as string),
    },
    {
      field: 'phoneNumber',
      headerName: 'Phone Number',
      type: 'string',
      width: 150,
    },
    {
      field: 'flags',
      headerName: 'Flags',
      type: 'array',
      width: 50,
      renderCell: (params) => {
        const flags = params.row.flags;
        return (
          <div>
            <LightTooltip
              placement="left-start"
              title={
                <div
                  style={{
                    width: '200px',
                    minHeight: 'fit-content',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '5px',
                    overflowWrap: 'normal',
                  }}
                >
                  {flags.map((flag, index: number) => (
                    <Chip key={index} label={flag} variant="outlined" />
                  ))}
                </div>
              }
            >
              <Badge badgeContent={flags.length} color="primary"></Badge>
            </LightTooltip>
            {/* {flags.map((flag, index: number) => (
              <Chip key={index} label={flag} variant="outlined" />
            ))} */}
          </div>
        );
      },
    },
  ];
  if (error) {
    return <Typography color="red">{error.toString()}</Typography>;
  }

  if (loading) {
    return <CircularProgress />;
  }
  if (data) {
    return (
      <>
        <SuccessSnackbar
          isOpen={openSuccessSnackbar}
          onClose={() => setOpenSuccessSnackbar(false)}
          message={successSnackbarMessage}
        />
        <ErrorSnackbar
          isOpen={openErrorSnackbar}
          onClose={() => setOpenErrorSnackbar(false)}
          message={errorSnackbarMessage}
        />
        <UserForm
          key={openUserForm.toString()}
          user={editUser}
          isOpen={openUserForm}
          onClose={() => {
            setEditUser(null);
            setOpenUserForm(false);
          }}
          runGetAllUsersQuery={runQuery}
          setOpenSuccessSnackbar={setOpenSuccessSnackbar}
          setSuccessSnackbarMessage={setSuccessSnackbarMessage}
          setOpenErrorSnackbar={setOpenErrorSnackbar}
          setErrorSnackbarMessage={setErrorSnackbarMessage}
        />
        <BulkUsersForm
          open={openBulkUserForm}
          onClose={() => setOpenBulkUserForm(false)}
          organizationId={id}
          runGetAllUsersQuery={runQuery}
          errorSnackbarMessage={errorSnackbarMessage}
          setSuccessSnackbarMessage={setSuccessSnackbarMessage}
          setErrorSnackbarMessage={setErrorSnackbarMessage}
        />
        <Container
          sx={{
            marginBottom: '1.5rem',
          }}
        >
          <div className="listViewHeaderContainer">
            <h1 className="listViewHeaderText">
              User List for Organization: {id}
            </h1>

            <div style={{ display: 'flex', gap: 20 }}>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => setOpenBulkUserForm(true)}
                className="createButton"
              >
                Bulk User Creation
              </Button>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => {
                  setEditUser(null);
                  createUser();
                }}
                className="createButton"
              >
                Create
              </Button>
            </div>
          </div>
          <DataGrid
            rows={data}
            columns={columns}
            initialState={{
              sorting: {
                sortModel: [{ field: 'id', sort: 'asc' }],
              },
            }}
            sx={{ backgroundColor: 'white' }}
            // make this full width
            autoHeight
          />
        </Container>
      </>
    );
  }

  return <div>Something went wrong</div>;
}
