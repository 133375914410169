import { FetchResponse } from '@api/api';
import { useCallback, useEffect, useRef, useState } from 'react';

type UseQueryReturn<TReturn, TParams extends any[]> = {
  /** The data from the query, or null if the query hasn't finished yet. Null if the query had an error. */
  data: TReturn | null;
  /** True if the query is running */
  loading: boolean;
  /** Any error from the query. Can be either a code error or a network error from fetch. */
  error: Error | null;
  /** The HTTP status code */
  statusCode: number | null;
  /** Can be called to run the query. Any parameters will be passed directly to the `queryFunction` */
  runQuery: (...params: TParams) => Promise<{
    data: TReturn | null;
    statusCode: number | null;
    error: Error | null;
  }>;
};

type QueryFunction<TReturn, TParams extends any[] = any[]> = (
  ...params: TParams
) => Promise<FetchResponse<TReturn>>;

type UseQueryOptions<TReturn> = {
  onSuccess?: (data: TReturn) => void;
  onError?: (err: Error) => void;
  isLoadingImmediately?: boolean;
};
/**
 * A hook to run a query and return the loading state, data, and any errors that occur.
 *
 * The query will run immediately as soon as the component is mounted.
 *
 * Usage:
 *
 * ```tsx
 *  type MyApiReturn = { id: number, name: string };
 *
 *  export const getTrial = (trialId) => get<MyApiReturn>(`/trials/${trialId}`);
 *
 *  const MyComponent = ({ trialId }) => {
 *    const { data, loading, error } = useQuery(() => getTrial(trialId));
 *
 *    if (loading) {
 *      return <SpinnerOrError />
 *    }
 *
 *    if (error) {
 *      return <SpinnerOrError error="Some error ocurred and we show this message to the user" />
 *    }
 *
 *    if (data) {
 *      return <RenderStuff trial={data} />
 *    }
 *  }
 *
 * ```
 *
 * @param queryFunction - A function that just wraps a fetch from the API (get, post, put, etc.). DO NOT call `res.json()` here, the hook will do that itself.
 * @param options - Optional, allows using callback functions like `onSuccess` or `onError`
 */
export function useQuery<TReturn, TParams extends any[] = any[]>(
  queryFunction: QueryFunction<TReturn, TParams>,
  options?: UseQueryOptions<TReturn>
): UseQueryReturn<TReturn, TParams> {
  const lazyQuery = useLazyQuery(queryFunction, {
    ...options,
    isLoadingImmediately: true,
  });

  useEffect(() => {
    lazyQuery.runQuery(...([] as any));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return lazyQuery;
}

/**
 * A hook that returns a function to trigger a query. Handles loading state and any errors that occur.
 *
 * Usage:
 *
 * ```tsx
 *  type MyApiReturn = { id: number, name: string };
 *
 *  type MyApiBody = { id: number, name: string };
 *
 *  export const postInitiative = (initiative) => post<MyApiReturn, MyApiBody>('/initiative', initiative);
 *
 *
 *  const MyComponent = ({ trialId }) => {
 *    const [initiativeFormData, setInitiativeFormData] = useState({ name: "My Initiative", description: "Some Description" })
 *
 *    const { data, loading, error, runQuery } = useLazyQuery(() => postInitiative(initiativeFormData));
 *
 *    return <Button onClick={() => runQuery()} />
 *  }
 *
 * ```
 *
 * OR you can pass the data to `runQuery` like this:
 *
 * ```tsx
 *  export const postInitiative = (initiative) => post('/initiative', initiative);
 *
 *  const MyComponent = ({ trialId }) => {
 *    const [initiativeFormData, setInitiativeFormData] = useState({ name: "My Initiative", description: "Some Description" })
 *
 *    const { data, loading, error, runQuery } = useLazyQuery(postInitiative);
 *
 *    return <Button onClick={() => runQuery(initiativeFormData)} />
 *  }
 * ```
 *
 * @param queryFunction - A function that just wraps a fetch from the API (get, post, put, etc.). DO NOT call `res.json()` here, the hook will do that itself.
 * @param options - Optional, allows using callback functions like `onSuccess` or `onError`
 */
export function useLazyQuery<TReturn, TParams extends any[] = any[]>(
  queryFunction: QueryFunction<TReturn, TParams>,
  options?: UseQueryOptions<TReturn>
): UseQueryReturn<TReturn, TParams> {
  const [data, setData] = useState<TReturn | null>(null);
  const promiseRef = useRef<Promise<FetchResponse<TReturn>> | null>(null);
  const [loading, setLoading] = useState(
    options?.isLoadingImmediately ?? false
  );
  const [error, setError] = useState<Error | null>(null);
  const [statusCode, setStatusCode] = useState<number | null>(null);

  const runQuery = useCallback<UseQueryReturn<TReturn, TParams>['runQuery']>(
    async (...parameters: TParams) => {
      setError(null);
      setLoading(true);
      setStatusCode(null);

      try {
        const promise = queryFunction(...parameters);
        promiseRef.current = promise;

        const response = await promise;

        // This means that a new query was triggered before this one finished. Don't update the state.
        if (promiseRef.current !== promise) {
          return { data: null, statusCode: null, error: null };
        }

        setStatusCode(response.status);

        if (!response.ok) {
          const contentType = response.headers.get('content-type');
          let errorJson: { message?: string; error?: string } = {};
          if (contentType?.startsWith('text')) {
            const text = await response.text();
            errorJson = { message: text };
          } else {
            errorJson = (await response.json()) as { message?: string };
          }
          setData(null);
          setLoading(false);
          const fetchError = new Error(
            errorJson?.message ?? errorJson?.error ?? 'Unknown error'
          );
          setError(fetchError);
          if (options?.onError != null) {
            options.onError(fetchError);
          }

          return { data: null, statusCode: response.status, error: fetchError };
        }

        const result = await response.json();
        setData(result);
        setError(null);
        setLoading(false);

        if (options?.onSuccess != null) {
          options.onSuccess(result);
        }

        return { data: result, statusCode: response.status, error: null };
      } catch (err) {
        console.error(err);
        setData(null);
        setLoading(false);
        setError(err as Error);

        if (options?.onError != null) {
          options.onError(err as Error);
        }

        return { data: null, statusCode: null, error: err as Error };
      }
    },
    [queryFunction, options]
  );

  return {
    data,
    loading,
    error,
    runQuery,
    statusCode,
  };
}
