import { Avatar } from '@mui/material';
import { useAuthContext } from 'pages/Login/useAuthContext';

export const StaffAvatar = () => {
  const { currentUser } = useAuthContext();

  return (
    <Avatar
      {...stringAvatar(`${currentUser!.firstName} ${currentUser?.lastName}`)}
    />
  );
};

// Copied from: https://mui.com/material-ui/react-avatar/#letter-avatars
function stringToColor(string: string) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}
