import { FeatureFlagsFocusView } from 'pages/FeatureFlags/FocusView/FeatureFlagsFocusView';
import { FeatureFlagsListView } from 'pages/FeatureFlags/FeatureFlagsListView';
import { Route, Routes } from 'react-router-dom';

export const FeatureFlagsRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<FeatureFlagsListView />} />
      <Route path=":id" element={<FeatureFlagsFocusView />} />
    </Routes>
  );
};
