import { useLazyQuery } from '@api/useQuery';
import {
  ICreateStaffUserApi,
  createStaffUser,
} from '@api/user/createStaffUser';
import { IStaffUserNoPassword } from '@api/user/types/IStaff';
import {
  IUpdateStaffUserApi,
  updateStaffUser,
} from '@api/user/updateStaffUser';
import { FormErrorMessage } from '@components/forms/FormErrorMessage';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { StaffInputSingleCheckbox } from 'pages/Staff/StaffFormInputs/StaffInputSingleCheckbox';
import { FormInputText } from '@components/forms/input/FormInputText';
import { StaffRoleDropdown } from 'pages/Staff/StaffFormInputs/StaffRoleDropdown';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

// Interface for the form input
interface IFormInput {
  firstName: string;
  lastName: string;
  email: string;
  password?: string;
  isActive: boolean;
  role: string;
}

// Regex for password validation
const passwordRegex =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

// Form validation schema using Zod
const updateUserValidator = z.object({
  firstName: z.string().min(1, 'First name is required'),
  lastName: z.string().min(1, 'Last name is required'),
  email: z.string().email('Please enter a valid email address'),
  isActive: z.boolean(),
  role: z.string().min(1, 'Role is required'),
});

const createUserValidator = updateUserValidator.extend({
  password: z
    .string()
    .min(8, 'Password must be at least 8 characters long.')
    .regex(
      passwordRegex,
      'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character.'
    ),
});

/**
 * Utility function to format the role in a user-friendly format
 * @param role the role of the user
 * @returns the role in a user-friendly format
 */
const formatRole = (role: string | undefined) => {
  // If role is undefined, return empty string
  if (!role) return '';
  switch (role) {
    case 'ADMIN':
      return 'Admin';
    default:
      return '';
  }
};

export interface IStaffUserForm {
  staffUser: IStaffUserNoPassword | null;
  isOpen: boolean;
  onClose: () => void;
  runGetAllUsersQuery: () => void;
  setOpenSuccessSnackbar: (open: boolean) => void;
  setSuccessSnackbarMessage: (message: string) => void;
  setOpenErrorSnackbar: (open: boolean) => void;
  setErrorSnackbarMessage: (message: string) => void;
}

export function StaffUserForm(props: IStaffUserForm) {
  // Deconstruct props
  const {
    staffUser,
    isOpen,
    onClose,
    runGetAllUsersQuery,
    setOpenSuccessSnackbar,
    setSuccessSnackbarMessage,
    setOpenErrorSnackbar,
    setErrorSnackbarMessage,
  } = props;

  /**
   * Default values for the form input
   */
  const defaultFormValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    isActive: true,
    ...staffUser,
    role: formatRole(staffUser?.role ?? 'ADMIN'),
  };

  // Form methods
  const methods = useForm<IFormInput>({
    defaultValues: defaultFormValues,
    resolver: staffUser
      ? zodResolver(updateUserValidator)
      : zodResolver(createUserValidator),
  });
  const { handleSubmit, reset, control } = methods;
  const [formError, setFormError] = useState<boolean>(false);
  const [formErrorMessage, setFormErrorMessage] = useState<string>('');

  // Queries
  const { runQuery: runCreateUserQuery } = useLazyQuery(
    (newUserToSave: ICreateStaffUserApi) => {
      return createStaffUser({ ...newUserToSave });
    },
    {
      onSuccess: () => {
        setFormError(false);
        setFormErrorMessage('');
        setOpenSuccessSnackbar(true);
        setSuccessSnackbarMessage('User created successfully!');
        runGetAllUsersQuery();
        handleClose();
      },
      onError: (error) => {
        setFormError(true);
        setFormErrorMessage(error.message);
        setErrorSnackbarMessage('Error creating user :(');
        setOpenErrorSnackbar(true);
      },
    }
  );
  const { runQuery: runEditUserQuery } = useLazyQuery(
    (editedUserToSave: IUpdateStaffUserApi) => {
      return updateStaffUser(staffUser!.id, editedUserToSave);
    },
    {
      onSuccess: () => {
        setFormError(false);
        setFormErrorMessage('');
        setOpenSuccessSnackbar(true);
        setSuccessSnackbarMessage('User updated successfully!');
        runGetAllUsersQuery();
        handleClose();
      },
      onError: (error) => {
        setFormError(true);
        setFormErrorMessage(error.message);
        setErrorSnackbarMessage('Error updating user :(');
        setOpenErrorSnackbar(true);
      },
    }
  );

  const onSubmit = (data: IFormInput) => {
    if (staffUser) {
      // Edit
      const editedUserToSave = {
        firstName: data.firstName,
        lastName: data.lastName,
        isActive: data.isActive,
        email: data.email,
        role: data.role.toUpperCase(),
      };
      runEditUserQuery(editedUserToSave);
    } else {
      const newUserToSave = {
        firstName: data.firstName,
        lastName: data.lastName,
        isActive: data.isActive,
        email: data.email,
        password: data.password!,
        role: data.role.toUpperCase(),
      };
      runCreateUserQuery(newUserToSave);
    }
  };

  /**
   * Function to handle closing the dialog and resetting the form
   */
  const handleClose = () => {
    setFormErrorMessage('');
    reset(defaultFormValues);
    onClose();
  };

  return (
    <>
      <Dialog fullWidth open={isOpen} onClose={handleClose}>
        <DialogTitle>{staffUser ? 'Edit' : 'Create '} Staff User</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please fill out the following information to{' '}
            {staffUser ? 'edit the' : 'create a '} staff user.
          </DialogContentText>
          {
            // If there is an error creating the user, display the error message
            formError && formErrorMessage && (
              <FormErrorMessage error={'Error: ' + formErrorMessage} />
            )
          }
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '20px',
              marginTop: '20px',
            }}
          >
            <FormInputText
              name="firstName"
              control={control}
              label="First Name"
              styles={{ width: '200px' }}
            />
            <FormInputText
              name="lastName"
              control={control}
              label="Last Name"
              styles={{ width: '200px' }}
            />
            <StaffInputSingleCheckbox
              name="isActive"
              control={control}
              label=""
            />
            <StaffRoleDropdown
              name="role"
              control={control}
              label="Role"
              styles={{ width: '200px' }}
            />
          </Box>
          <FormInputText
            name="email"
            control={control}
            label="Email"
            styles={{ marginTop: '20px' }}
          />
          {
            // If there is no staff user, display the password field
            !staffUser && (
              <FormInputText
                name="password"
                control={control}
                label="Password"
                styles={{ marginTop: '20px' }}
              />
            )
          }
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '20px',
            padding: '0px 20px 15px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '10px',
            }}
          >
            <Button onClick={handleClose} variant="outlined">
              Cancel
            </Button>

            <Button
              onClick={() => {
                reset(defaultFormValues);
              }}
              variant="outlined"
            >
              Reset
            </Button>
          </Box>
          <Button onClick={handleSubmit(onSubmit)} variant={'contained'}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
