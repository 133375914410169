import React, { ReactNode } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogProps,
} from '@mui/material';

type ReusableDialogProps = DialogProps & {
  title: string;
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  actions?: ReactNode;
};

const ReusableDialog: React.FC<ReusableDialogProps> = ({
  title,
  open,
  onClose,
  children,
  actions,
  ...rest
}) => {
  return (
    <Dialog open={open} onClose={onClose} {...rest}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  );
};

export default ReusableDialog;
