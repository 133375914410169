import { userRoles } from '@api/user/types/IUser';
import { FormControl, MenuItem, TextField } from '@mui/material';
import { Controller, FieldValues } from 'react-hook-form';
import { StaffFormInputProps } from 'pages/Staff/StaffFormInputs/StaffFormInputProps';

export function UserAccessDropdown<TFieldValues extends FieldValues>({
  name,
  control,
  styles,
}: StaffFormInputProps<TFieldValues>) {
  return (
    <FormControl style={styles} fullWidth>
      <Controller
        render={({ field: { onChange, value } }) => (
          <TextField
            select
            label="Access"
            placeholder="Access"
            variant="outlined"
            value={value}
            onChange={onChange}
          >
            {userRoles.map((role) => (
              <MenuItem key={role} value={role}>
                {role}
              </MenuItem>
            ))}
          </TextField>
        )}
        control={control}
        name={name}
      />
    </FormControl>
  );
}
