import { put } from '@api/api';
import { IStaffUserNoPassword } from '@api/user/types/IStaff';

export interface IUpdatePasswordApi {
  password: string;
  newPassword: string;
  confirmPassword: string;
}

export function updatePassword(toUpdate: IUpdatePasswordApi) {
  return put<IStaffUserNoPassword>(`/changePassword`, toUpdate);
}
