import { Navigate, Route, Routes } from 'react-router-dom';

import { Typography } from '@mui/material';
import { FeatureFlagsRoutes } from 'pages/FeatureFlags/FeatureFlagsRoutes';
import { OrganizationRoutes } from 'pages/Organizations/OrganizationRoutes';
import { StaffListView } from 'pages/Staff/StaffListView';
import { UsersListView } from 'pages/Users/UsersListView';
import { AuditLogView } from 'pages/AuditLogs/AuditLogView';

export const RoutesAdmin = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/organizations" replace />} />
      <Route path="/organizations/*" element={<OrganizationRoutes />} />
      <Route path="/staff-users/*" element={<StaffListView />} />
      <Route path="/feature-flags/*" element={<FeatureFlagsRoutes />} />
      <Route path="/audit-logs" element={<AuditLogView />} />
      <Route path="/users/*" element={<UsersListView />} />

      <Route
        path="*"
        element={
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
            }}
          >
            <Typography variant="h2" color="darkred">
              <b>404 - Page Not Found</b>
            </Typography>
          </div>
        }
      />
    </Routes>
  );
};
