import { Box } from '@mui/material';
import { appBarHeight } from '@components/nav/constants';
import { TopNav } from '@components/nav/TopNav';
import { LeftNav } from '@components/nav/LeftNav';

type LeftNavProps = {
  children: React.ReactNode;
};

export const Layout = (props: LeftNavProps) => {
  const { children } = props;
  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <TopNav />
      <LeftNav />
      {/* The main body of the site. */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          backgroundColor: '#fafafb',
          padding: '12px 24px',
          minHeight: '100vh',
          // padding here must match the height of the AppBar
          paddingTop: `${appBarHeight}px`,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
