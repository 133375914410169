import { get } from '@api/api';
import { IStaffUserNoPassword } from '@api/user/types/IStaff';

export interface IStaffUserFilters {
  email: string | undefined;
}

/**
 * Builds the query string for a GET request that uses the email filter.
 */
export function buildStaffUserFilters(selectedFilters: IStaffUserFilters) {
  if (!selectedFilters) {
    return '';
  }

  const url = new URLSearchParams({});
  const { email } = selectedFilters;

  if (email) {
    url.append('email', email);
  }

  // Decode this, the API re-encodes the query string so we need to avoid double encoding.
  return decodeURIComponent(url.toString());
}

export async function getStaffUsersByFilters(
  selectedFilters: IStaffUserFilters
) {
  const filters = buildStaffUserFilters(selectedFilters);
  return get<IStaffUserNoPassword[]>(`/staff?${filters}`);
}
