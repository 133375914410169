import * as React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { ParsedUserType } from 'pages/Users/BulkUsersForm/utils';
import { Typography } from '@mui/material';

interface RowData {
  id: number;
  firstName: string;
  lastName: string;
  access: string;
  email: string;
  jobType?: string;
  phoneNumber?: number;
}

interface UsersGridSectionProps {
  parsedUsers: ParsedUserType[];
  currentFileName: string;
  errorSnackbarMessage: string | undefined;
}

const columns: GridColDef[] = [
  { field: 'firstName', headerName: 'First Name', width: 150 },
  { field: 'lastName', headerName: 'Last Name', width: 150 },
  { field: 'access', headerName: 'Access', width: 120 },
  { field: 'email', headerName: 'Email', width: 200 },
  { field: 'jobType', headerName: 'Job Type', width: 150 },
  { field: 'phoneNumber', headerName: 'Phone Number', width: 150 },
];

const UsersGridSection: React.FC<UsersGridSectionProps> = ({
  parsedUsers,
  currentFileName,
  errorSnackbarMessage,
}) => {
  const [data, setData] = React.useState<RowData[]>([]);

  React.useEffect(() => {
    const transformedData = parsedUsers.map((item, index) => ({
      id: index,
      firstName: item['First Name (Required)'],
      lastName: item['Last Name (Required)'],
      access: item['Access: User or Admin (Required)'],
      email: item['Email address (Required)'],
      jobType: item['Job Type (optional)'],
      phoneNumber: item['Phone number (optional)'],
    }));

    setData(transformedData);
  }, [parsedUsers]);

  return (
    <div style={styles.gridContainer}>
      <span>{currentFileName}</span>
      <div style={styles.dataGridWrapper}>
        <DataGrid
          rows={data}
          columns={columns}
          initialState={{
            sorting: {
              sortModel: [{ field: 'id', sort: 'asc' }],
            },
          }}
          sx={styles.dataGrid}
          autoHeight
        />
      </div>
      {errorSnackbarMessage && (
        <Typography color="red">{errorSnackbarMessage}</Typography>
      )}
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  gridContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    height: '50vh',
    width: '100%',
    overflow: 'auto',
  },
  dataGridWrapper: {
    overflow: 'auto',
  },
  dataGrid: {
    backgroundColor: 'white',
    height: '50vh',
    width: '100%',
  },
};

export default UsersGridSection;
