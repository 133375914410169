import { getFeatureFlagsAndEnablers } from '@api/featureFlags/getFeatureFlagsAndEnablers';
import { useLazyQuery, useQuery } from '@api/useQuery';
import { ICreateUserApi, createUser } from '@api/user/createUser';
import { IUser } from '@api/user/types/IUser';
import { IUpdateUserApi, updateUser } from '@api/user/updateUser';
import { FormErrorMessage } from '@components/forms/FormErrorMessage';
import { FormInputText } from '@components/forms/input/FormInputText';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { z } from 'zod';
import { UserAccessDropdown } from './UserFormInputs/UserAccessDropdown';
import { UserInputSingleCheckbox } from './UserFormInputs/UserInputSingleCheckbox';

// Interface for the form input
interface IFormInput {
  firstName: string;
  lastName: string;
  email: string;
  status: boolean;
  access: string;
  phoneNumber: string;
  flags: string[];
}

const phoneRegex = new RegExp(/^\d*$/);
// Form validation schema using Zod
const updateUserValidator = z.object({
  firstName: z.string().min(1, 'First name is required'),
  lastName: z.string().min(1, 'Last name is required'),
  email: z.string().email('Please enter a valid email address'),
  status: z.boolean(),
  access: z.string().min(1, 'Role is required'),
  flags: z.array(z.string()).optional(),
  phoneNumber: z
    .string()
    .regex(phoneRegex, 'Please enter a valid phone number')
    .optional(),
});

export interface IUserForm {
  user: IUser | null;
  isOpen: boolean;
  onClose: () => void;
  runGetAllUsersQuery: () => void;
  setOpenSuccessSnackbar: (open: boolean) => void;
  setSuccessSnackbarMessage: (message: string) => void;
  setOpenErrorSnackbar: (open: boolean) => void;
  setErrorSnackbarMessage: (message: string) => void;
}

function mapUser(user: IUser): IFormInput {
  return {
    firstName: user.firstName ? user.firstName : '',
    lastName: user.lastName ? user.lastName : '',
    email: user.email ? user.email : '',
    status: user.status ? user.status : false,
    access: user.access ? user.access : '',
    phoneNumber: user.phoneNumber ? user.phoneNumber : '',
    flags: user.flags ? user.flags : [],
  };
}

export function UserForm(props: IUserForm) {
  // Deconstruct props
  const {
    user,
    isOpen,
    onClose,
    runGetAllUsersQuery,
    setOpenSuccessSnackbar,
    setSuccessSnackbarMessage,
    setOpenErrorSnackbar,
    setErrorSnackbarMessage,
  } = props;

  const { id } = useParams();
  /**
   * Default values for the form input
   */

  const defaultFormValues = {
    firstName: '',
    lastName: '',
    email: '',
    status: true,
    access: '',
    phoneNumber: '',
    flags: [],
  };

  // Form methods
  const methods = useForm<IFormInput>({
    defaultValues: user ? mapUser(user) : defaultFormValues,
    resolver: zodResolver(updateUserValidator),
  });
  const { handleSubmit, reset, control } = methods;

  // Queries
  const { data: featureFlags } = useQuery(getFeatureFlagsAndEnablers); // add back loading and error checks if this creates issues

  const { error: createUserError, runQuery: runCreateUserQuery } = useLazyQuery(
    (newUserToSave: ICreateUserApi) => {
      return createUser({ ...newUserToSave });
    },
    {
      onSuccess: () => {
        setOpenSuccessSnackbar(true);
        setSuccessSnackbarMessage('User created successfully!');
        runGetAllUsersQuery();
        handleClose();
      },
      onError: () => {
        setErrorSnackbarMessage('Error creating user :(');
        setOpenErrorSnackbar(true);
      },
    }
  );
  const { error: updateUserError, runQuery: runEditUserQuery } = useLazyQuery(
    (editedUserToSave: IUpdateUserApi) => {
      return updateUser(user!.id, editedUserToSave);
    },
    {
      onSuccess: () => {
        setOpenSuccessSnackbar(true);
        setSuccessSnackbarMessage('User updated successfully!');
        runGetAllUsersQuery();
        handleClose();
      },
      onError: () => {
        setErrorSnackbarMessage('Error updating user :(');
        setOpenErrorSnackbar(true);
      },
    }
  );

  const onSubmit = (data: IFormInput) => {
    if (user) {
      // Edit
      const editedUserToSave = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        status: true,
        access: data.access,
        organizationId: Number(id),
        phoneNumber:
          data.phoneNumber?.length > 1 ? Number(data.phoneNumber) : null,
        flags: data.flags,
      };
      runEditUserQuery(editedUserToSave);
    } else {
      const newUserToSave = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        status: data.status,
        access: data.access,
        organizationId: Number(id),
        phoneNumber:
          data.phoneNumber?.length > 1 ? Number(data.phoneNumber) : null,
        flags: data.flags,
      };
      runCreateUserQuery(newUserToSave);
    }
  };

  const error = createUserError || updateUserError;

  /**
   * Function to handle closing the dialog and resetting the form
   */
  const handleClose = () => {
    reset(defaultFormValues);
    onClose();
  };

  return (
    <>
      <Dialog
        maxWidth="sm"
        open={isOpen}
        onClose={handleClose}
        sx={{
          '& .MuiDialog-container': {
            alignItems: 'flex-start',
          },
        }}
      >
        {' '}
        <DialogTitle>{user ? 'Edit' : 'Create '} User</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please fill out the following information to{' '}
            {user ? 'edit the' : 'create a '} user.
          </DialogContentText>
          {
            // If there is an error creating the user, display the error message
            error && <FormErrorMessage error={'Error: ' + error.message} />
          }
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '20px',
              marginTop: '20px',
            }}
          >
            <FormInputText
              name="firstName"
              control={control}
              label="First Name"
              styles={{ flex: 1 }}
            />
            <FormInputText
              name="lastName"
              control={control}
              label="Last Name"
              styles={{ flex: 1 }}
            />
            {user && (
              <UserInputSingleCheckbox
                name="status"
                control={control}
                label="Status"
                styles={{ width: '80px' }}
              />
            )}
            <UserAccessDropdown
              name="access"
              control={control}
              label="Access"
            />
            <FormInputText name="email" control={control} label="Email" />
            <Controller
              name={'phoneNumber'}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  helperText={error ? error.message : null}
                  error={!!error}
                  onChange={onChange}
                  value={value}
                  style={{ width: '200px' }}
                  fullWidth
                  label={'Phone Number'}
                  variant="outlined"
                />
              )}
            />
            <Controller
              name="flags"
              control={control}
              defaultValue={[]}
              render={({ field }) => (
                <Autocomplete
                  multiple
                  options={featureFlags?.map((flag) => flag.id) ?? []}
                  value={field.value}
                  onChange={(_e, newValue) => {
                    field.onChange(newValue);
                  }}
                  getOptionLabel={(option) =>
                    featureFlags?.find((flag) => flag.id === option)
                      ?.description ?? ''
                  }
                  isOptionEqualToValue={(option, value) => option === value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Feature Flags"
                    />
                  )}
                  style={{ flex: 1 }}
                />
              )}
            />
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '20px',
            padding: '0px 20px 15px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '10px',
            }}
          >
            <Button onClick={handleClose} variant="outlined">
              Cancel
            </Button>

            <Button
              onClick={() => {
                reset(defaultFormValues);
              }}
              variant="outlined"
            >
              Reset
            </Button>
          </Box>
          <Button onClick={handleSubmit(onSubmit)} variant={'contained'}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
