import { OrganizationsFocusView } from 'pages/Organizations/OrganizationFocusView';
import { OrganizationsListView } from 'pages/Organizations/OrganizationsListView';
import { UsersListView } from 'pages/Users/UsersListView';
import { Route, Routes } from 'react-router-dom';

export const OrganizationRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<OrganizationsListView />} />
      <Route path=":id" element={<OrganizationsFocusView />} />
      <Route path=":id/users" element={<UsersListView />} />
    </Routes>
  );
};
