import React from 'react';
import { Alert, AlertTitle } from '@mui/material';

interface ValidationError {
  validation: string;
  code: string;
  message: string;
  path: string[];
}

export interface RowError {
  row: number;
  errors: ValidationError[];
}

interface ErrorDisplayProps {
  errors: RowError[];
}

const ErrorDisplay: React.FC<ErrorDisplayProps> = ({ errors }) => (
  <>
    {errors.map((error, index) => (
      <Alert key={index} severity="error">
        <AlertTitle>Error in row {error.row}</AlertTitle>
        {error.errors.map((subError, subIndex) => (
          <div key={subIndex}>
            <strong>{subError.validation || subError.path[0]}: </strong>
            {subError.message}
          </div>
        ))}
      </Alert>
    ))}
  </>
);

export default ErrorDisplay;
