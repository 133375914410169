import { Link } from 'react-router-dom';

import { ButtonBase, SxProps } from '@mui/material';

import hippoSvg from 'assets/hippo.svg';

type LogoButtonProps = {
  sx?: SxProps;
  to: string;
  children?: React.ReactNode;
};

export const LogoButton = (props: LogoButtonProps) => {
  const { sx, to, children } = props;

  return (
    <ButtonBase disableRipple component={Link as any} sx={sx} to={to}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ flex: '0 0 auto' }}>
          <img src={hippoSvg} alt="Hippo Logo" style={{ width: 70 }} />
        </div>
        <div style={{ flex: '1 1 auto' }}>{children}</div>
      </div>
    </ButtonBase>
  );
};
