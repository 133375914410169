import { post } from '@api/api';

export type ICreateOrganizationPayload = {
  name: string;
  active: boolean;
  flags: string[];
  systemName?: string;
};

export function createOrganization(toCreate: ICreateOrganizationPayload) {
  toCreate.systemName = toCreate.name.toLowerCase().replace(/ /g, '-');
  return post('/organization', toCreate);
}
