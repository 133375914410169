import { put } from '@api/api';

export type IUpdateOrganizationsPayload = {
  id: number;
  name: string;
  active: boolean;
  flags: string[];
};

export function updateOrganization(
  id: number,
  toUpdate: IUpdateOrganizationsPayload
) {
  return put(`/organization/${id}`, toUpdate);
}
