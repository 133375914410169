import { put } from '@api/api';
import { IUser } from './types/IUser';

export interface IUpdateUserApi {
  firstName: string;
  lastName: string;
  email: string;
  status: boolean;
  access: string;
  organizationId: number;
  flags: string[];
  phoneNumber: number | null;
}

export function updateUser(id: number, toUpdate: IUpdateUserApi) {
  return put<IUser>(`/users/${id}`, toUpdate);
}
