import { put } from '@api/api';
import { IFeatureFlag } from '@api/featureFlags/types/IFeatureFlags';

export interface IUpdateEnabledOrganizationsForAFeatureFlagApi {
  id: number;
  enabled: boolean;
}

export function updateEnabledOrganizationsForAFeatureFlag(
  id: string,
  toUpdate: IUpdateEnabledOrganizationsForAFeatureFlagApi[]
) {
  return put<IFeatureFlag>(`/feature-flag/${id}`, toUpdate);
}
