import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
} from '@mui/material';
import { Controller, FieldValues } from 'react-hook-form';
import { StaffFormInputProps } from './StaffFormInputProps';

export function StaffInputSingleCheckbox<TFieldValues extends FieldValues>({
  name,
  control,
  label,
  styles,
}: StaffFormInputProps<TFieldValues>) {
  return (
    <FormControl size={'small'} variant={'outlined'} style={styles}>
      <FormLabel component="legend">{label}</FormLabel>
      <div>
        <FormControlLabel
          control={
            <Controller
              name={name}
              control={control}
              render={({ field: props }) => (
                <Checkbox
                  {...props}
                  checked={props.value}
                  onChange={(e) => props.onChange(e.target.checked)}
                />
              )}
            />
          }
          label="Active"
        />
      </div>
    </FormControl>
  );
}
