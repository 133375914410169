import React from 'react';
import { Button, Input } from '@mui/material';

interface UploadSectionProps {
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const UploadSection: React.FC<UploadSectionProps> = ({ handleFileChange }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 10,
        height: '20vh',
      }}
    >
      <Input
        type="file"
        onChange={handleFileChange}
        inputProps={{ accept: '.xlsx, .xls' }}
        style={{ display: 'none' }}
        id="user-file-upload"
      />
      <label htmlFor="user-file-upload">
        <Button variant="outlined" component="span">
          Upload Users Spreadsheet
        </Button>
      </label>
    </div>
  );
};

export default UploadSection;
