import { Box, Stack, Typography } from '@mui/material';

import { LogoButton } from '@components/logo/LogoButton';

export const NavHeader = () => {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '8px',
        paddingBottom: '8px',
      }}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <LogoButton to="/">
          <Typography
            variant="h6"
            component="h1"
            sx={{ marginLeft: '8px', color: '#004985' }}
          >
            <b>Admin Portal</b>
          </Typography>
        </LogoButton>
      </Stack>
    </Box>
  );
};
