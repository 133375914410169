import { get } from '@api/api';

export type GetCurrentUserApiReturn = {
  id: number;
  firstName: string;
  lastName: string;
  role: string;
};

export function getCurrentUser() {
  return get<GetCurrentUserApiReturn>('/current-user');
}
