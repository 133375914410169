import { GetCurrentUserApiReturn } from '@api/user/getCurrentUser';
import { createContext, useContext } from 'react';

export type AuthContextReturn = {
  currentUser: GetCurrentUserApiReturn | null;
  logoutUser: () => void;
  /** Takes in the user's login info and returns their JWT */
  loginUser: (username: string, password: string) => Promise<void>;
};

export const AuthContext = createContext<AuthContextReturn>({
  currentUser: null,
  // We need defaults for these functions since React requires it, but no code can access these since we setup the functions below.
  logoutUser: () => {
    console.log('This should never be hit.');
  },
  loginUser: async () => {
    console.log('This should never be hit.');
  },
});

export function useAuthContext() {
  return useContext(AuthContext);
}
