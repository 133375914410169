import { post } from '@api/api';
import { IUser } from './types/IUser';
import { ParsedUserType } from 'pages/Users/BulkUsersForm/utils';

export interface ICreateUserApi {
  firstName: string;
  lastName: string;
  email: string;
  status: boolean;
  access: string;
  organizationId: number;
  flags: string[];
  phoneNumber: number | null;
}

export type BulkUsersQueryType = {
  users: ParsedUserType[];
  organizationId: number;
};

export function createUser(toCreate: ICreateUserApi) {
  return post<IUser>('/users', toCreate);
}

export function createBulkUsers(toCreate: BulkUsersQueryType) {
  return post<IUser[]>('/users/bulk', toCreate);
}
