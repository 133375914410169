import { put } from '@api/api';
import { IStaffUserNoPassword } from '@api/user/types/IStaff';

export interface IUpdateStaffUserApi {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  isActive: boolean;
}

export function updateStaffUser(id: number, toUpdate: IUpdateStaffUserApi) {
  return put<IStaffUserNoPassword>(`/staff/${id}`, toUpdate);
}
