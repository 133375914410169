import { useQuery } from '@api/useQuery';
import { getStaffUsersByFilters } from '@api/user/getStaffUsersByFilters';
import { IStaffUserNoPassword } from '@api/user/types/IStaff';
import { ErrorSnackbar } from '@components/forms/snackbars/ErrorSnackbar';
import { SuccessSnackbar } from '@components/forms/snackbars/SuccessSnackbar';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { Button, CircularProgress, Container, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { capitalize } from 'lodash';
import { StaffUserForm } from 'pages/Staff/StaffUserForm';
import { useState } from 'react';
import './StaffPageStyles.css';

/**
 * Formats the role string to have only the first letter capitalized
 * @param role string indicating the user's role
 * @returns string with only the first letter capitalized
 */
const formatRole = (role: string) => {
  return capitalize(role);
};

export function StaffListView() {
  // State for the staff user form
  const [editUser, setEditUser] = useState<IStaffUserNoPassword | null>(null);
  const [openStaffUserForm, setOpenStaffUserForm] = useState(false);
  // State for the snackbar
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [successSnackbarMessage, setSuccessSnackbarMessage] = useState('');
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [errorSnackbarMessage, setErrorSnackbarMessage] = useState('');

  /**
   * Opens the create staff user form
   */
  const createStaffUser = () => {
    setOpenStaffUserForm(true);
  };

  // Define the columns for the table
  const columns: GridColDef<IStaffUserNoPassword>[] = [
    {
      field: 'id',
      headerName: 'ID',
      type: 'number',
      width: 75,
      headerClassName: 'dataGridHeader',
    },
    {
      field: 'firstName',
      headerName: 'First Name',
      width: 200,
      headerClassName: 'dataGridHeader',
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      width: 200,
      headerClassName: 'dataGridHeader',
    },
    {
      field: 'isActive',
      headerName: 'Active',
      type: 'boolean',
      width: 150,
      headerClassName: 'dataGridHeader',
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      headerClassName: 'dataGridHeader',
    },
    {
      field: 'role',
      headerName: 'Role',
      width: 102,
      headerClassName: 'dataGridHeader',
      valueFormatter: (params) => formatRole(params.value as string),
    },
    {
      field: 'edit',
      headerName: '',
      width: 100,
      headerClassName: 'dataGridHeader',
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <Button
          onClick={() => {
            setEditUser(params.row);
            setOpenStaffUserForm(true);
          }}
          startIcon={<EditIcon />}
          className="editButton"
        >
          Edit
        </Button>
      ),
    },
  ];

  const { data, runQuery, loading, error } = useQuery(() =>
    getStaffUsersByFilters({
      email: undefined,
    })
  );

  if (error) {
    return <Typography color="red">{error.toString()}</Typography>;
  }
  if (loading) {
    return <CircularProgress />;
  }
  if (data) {
    return (
      <>
        <StaffUserForm
          key={openStaffUserForm.toString()}
          staffUser={editUser}
          isOpen={openStaffUserForm}
          onClose={() => {
            setEditUser(null);
            setOpenStaffUserForm(false);
          }}
          runGetAllUsersQuery={runQuery}
          setOpenSuccessSnackbar={setOpenSuccessSnackbar}
          setSuccessSnackbarMessage={setSuccessSnackbarMessage}
          setOpenErrorSnackbar={setOpenErrorSnackbar}
          setErrorSnackbarMessage={setErrorSnackbarMessage}
        />
        <SuccessSnackbar
          isOpen={openSuccessSnackbar}
          onClose={() => setOpenSuccessSnackbar(false)}
          message={successSnackbarMessage}
        />
        <ErrorSnackbar
          isOpen={openErrorSnackbar}
          onClose={() => setOpenErrorSnackbar(false)}
          message={errorSnackbarMessage}
        />
        <Container
          sx={{
            marginBottom: '1.5rem',
          }}
        >
          <div className="listViewHeaderContainer">
            <h1 className="listViewHeaderText">Staff List</h1>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => {
                setEditUser(null);
                createStaffUser();
              }}
              className="createButton"
            >
              Create
            </Button>
          </div>
          <DataGrid
            rows={data}
            columns={columns}
            initialState={{
              sorting: {
                sortModel: [{ field: 'id', sort: 'asc' }],
              },
              pagination: { paginationModel: { pageSize: 25 } },
            }}
            sx={{ backgroundColor: 'white' }}
            autoHeight
            pageSizeOptions={[25, 50, 100]}
          />
        </Container>
      </>
    );
  }
  return <div>Something went wrong</div>;
}
