import { Container, Button } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';

export const OrganizationsFocusView = () => {
  const { id } = useParams<{ id: string }>();

  const navigate = useNavigate();

  return (
    <Container fixed>
      <h1>Focus view for Organization: {id}</h1>
      <Button
        onClick={() => navigate(`/organizations/${id}/users`)}
        variant="outlined"
      >
        Manage Users
      </Button>
    </Container>
  );
};
