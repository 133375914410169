import 'index.css';
// Import the Roboto font that material-ui depends on.
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import React from 'react';
import ReactDOM from 'react-dom/client';

import { intusTheme } from 'theme';

import { App } from 'App';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import { AuthContextProvider } from 'pages/Login/AuthContextProvider';

const rootElement = document.getElementById('root');

if (rootElement == null) {
  throw new Error("Couldn't find root element");
}

ReactDOM.createRoot(rootElement).render(
  <React.StrictMode>
    <ThemeProvider theme={intusTheme}>
      <CssBaseline />
      <BrowserRouter>
        {/* Handles only displaying the app for logged in users. */}
        <AuthContextProvider>
          <App />
        </AuthContextProvider>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>
);
