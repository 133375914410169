import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { IOrganizationRow } from '@api/featureFlags/types/IFeatureFlags';
import {
  IUpdateEnabledOrganizationsForAFeatureFlagApi,
  updateEnabledOrganizationsForAFeatureFlag,
} from '@api/featureFlags/updateEnabledFlagsForOrganizations';
import { useLazyQuery } from '@api/useQuery';
import { FormErrorMessage } from '@components/forms/FormErrorMessage';
import EditIcon from '@mui/icons-material/Edit';
import FlagIcon from '@mui/icons-material/Flag';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { produce } from 'immer';

interface IOrganizationTabPanel {
  initialRows: IOrganizationRow[];
  setUpdatedRows: (value: IOrganizationRow[]) => void;
  updatedRows: IOrganizationRow[];
  runGetFeatureFlagAndEnablersByIdQuery: () => void;
  setOpenSuccessSnackbar: (open: boolean) => void;
  setSuccessSnackbarMessage: (message: string) => void;
  setOpenErrorSnackbar: (open: boolean) => void;
  setErrorSnackbarMessage: (message: string) => void;
}

export function OrganizationTabPanel(props: IOrganizationTabPanel) {
  const { id } = useParams<{ id: string }>();
  const {
    initialRows,
    setUpdatedRows,
    updatedRows,
    runGetFeatureFlagAndEnablersByIdQuery,
    setOpenSuccessSnackbar,
    setSuccessSnackbarMessage,
    setOpenErrorSnackbar,
    setErrorSnackbarMessage,
  } = props;

  // State for the confirm dialog
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState('');
  // State for toggling all flags
  const [toggleFlags, setToggleFlags] = useState(true);

  /**
   * Function to update the enabled column for DataGrid rows
   * @param id number indicating the organization id
   */
  const updateEnabledColumn = (id: number) => {
    const newRows = produce(updatedRows, (draftRows: IOrganizationRow[]) => {
      const item = draftRows.find((item) => item.id == id);
      if (!item) {
        return;
      }
      item.enabled = !item.enabled;
    });
    setUpdatedRows(newRows);
  };

  const organizationColumns: GridColDef<IOrganizationRow>[] = [
    {
      field: 'id',
      headerName: 'ID',
      type: 'number',
      width: 75,
      headerClassName: 'dataGridHeader',
    },
    {
      field: 'enabled',
      headerName: 'Enabled',
      type: 'boolean',
      width: 100,
      headerClassName: 'dataGridHeader',
      renderCell: (params) => {
        return (
          <input
            type="checkbox"
            checked={params.row.enabled}
            onChange={() => {
              updateEnabledColumn(params.row.id);
            }}
          />
        );
      },
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      headerClassName: 'dataGridHeader',
    },
  ];

  // Queries
  const { runQuery: runUpdateFeatureFlagForOrganizationQuery } = useLazyQuery(
    (
      editedOrganizationToSave: IUpdateEnabledOrganizationsForAFeatureFlagApi[]
    ) => {
      return updateEnabledOrganizationsForAFeatureFlag(
        id!,
        editedOrganizationToSave
      );
    },
    {
      onSuccess: () => {
        setFormErrorMessage('');
        setUpdatedRows(initialRows);
        setOpenSuccessSnackbar(true);
        setSuccessSnackbarMessage(
          'Feature flag successfully enabled for organization!'
        );
        runGetFeatureFlagAndEnablersByIdQuery();
        handleClose();
      },
      onError: (error) => {
        setFormErrorMessage(error.message);
        setErrorSnackbarMessage('Feature flag could not be updated');
        setOpenErrorSnackbar(true);
      },
    }
  );

  /**
   * Closes the confirm dialog
   */
  const handleClose = () => {
    setOpenConfirmDialog(false);
  };

  /**
   * Handles the submit button click
   */
  const handleSubmit = () => {
    const rowsToUpdate = updatedRows.map((row) => ({
      id: row.id,
      enabled: row.enabled,
    }));
    runUpdateFeatureFlagForOrganizationQuery(rowsToUpdate);
  };

  /**
   * Check if the updated rows have changed to determine button enabled/disabled state
   * @returns boolean indicating whether the updated rows have changed
   */
  const checkUpdatedRowsChanged = () => {
    return updatedRows.every(
      (row, idx) =>
        row.id === initialRows[idx].id &&
        row.enabled === initialRows[idx].enabled
    );
  };

  /**
   * Iterates over all rows and toggles the enabled column
   */
  const toggleAllFlags = () => {
    const newRows = produce(updatedRows, (draftRows: IOrganizationRow[]) => {
      draftRows.forEach((row) => {
        row.enabled = toggleFlags!;
      });
    });
    setUpdatedRows(newRows);
    setToggleFlags(!toggleFlags);
  };

  /**
   * Function to render the confirm dialog
   * @returns TSX for the confirm dialog
   */
  const renderConfirmDialog = (
    <Dialog fullWidth open={openConfirmDialog}>
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent dividers>
        Pressing 'Yes' will update the organization.
        {
          // If there is an error creating the user, display the error message
          formErrorMessage.length > 0 && (
            <FormErrorMessage error={'Error: ' + formErrorMessage} />
          )
        }
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '15px',
          }}
        >
          <Button onClick={handleClose} variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleSubmit} variant="contained">
            Yes
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );

  return (
    <>
      {renderConfirmDialog}
      <div className="buttonContainer">
        <Button
          variant="contained"
          className="editButton"
          startIcon={<FlagIcon />}
          onClick={() => {
            toggleAllFlags();
          }}
        >
          Toggle All
        </Button>
        <Button
          variant="contained"
          className="editButton"
          startIcon={<EditIcon />}
          onClick={() => {
            setOpenConfirmDialog(true);
          }}
          disabled={checkUpdatedRowsChanged()}
        >
          Submit
        </Button>
        <Button
          variant="contained"
          className="editButton"
          startIcon={<RestartAltIcon />}
          onClick={() => {
            setUpdatedRows(JSON.parse(JSON.stringify(initialRows)));
          }}
          disabled={checkUpdatedRowsChanged()}
        >
          Reset
        </Button>
      </div>
      <DataGrid
        rows={updatedRows}
        columns={organizationColumns}
        initialState={{
          sorting: {
            sortModel: [{ field: 'name', sort: 'asc' }],
          },
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        sx={{
          backgroundColor: 'white',
        }}
        autoHeight
        pageSizeOptions={[10, 25, 50]}
      />
    </>
  );
}
