import { Layout } from '@components/layout/Layout';
import 'App.css';

import { RoutesAdmin } from 'RoutesAdmin';

export const App = () => {
  return (
    <Layout>
      <RoutesAdmin />
    </Layout>
  );
};
