import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getFeatureFlagAndEnablersById } from '@api/featureFlags/getFeatureFlagAndEnablersById';
import { IOrganizationRow } from '@api/featureFlags/types/IFeatureFlags';
import { getOrganizations } from '@api/organizations/getOrganizations';
import { useQuery } from '@api/useQuery';
import { ErrorSnackbar } from '@components/forms/snackbars/ErrorSnackbar';
import { SuccessSnackbar } from '@components/forms/snackbars/SuccessSnackbar';
import {
  Box,
  CircularProgress,
  Container,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { startCase } from 'lodash';
import { OrganizationTabPanel } from 'pages/FeatureFlags/FocusView/OrganizationTabPanel';
import { UserTabPanel } from 'pages/FeatureFlags/FocusView/UserTabPanel';
import '../FeatureFlagsStyles.css';
import { TabPanel } from './TabPanel';

/**
 * Utility function to get the props for the tab
 * @param index number indicating the index of the tab
 * @returns the props for the tab
 */
function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

/**
 * Formats the id string to have spaces and capitalization of each word
 * @param id string indicating the id of the feature flag
 * @returns the id with spaces and capitalization of each word
 */
const formatId = (id: string) => {
  return startCase(id.replaceAll('-', ' '));
};

export const FeatureFlagsFocusView = () => {
  const { id } = useParams<{ id: string }>();

  // State to toggle between enablers for feature flags
  const [tabValue, setTabValue] = useState(0);
  // State for the snackbar
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [successSnackbarMessage, setSuccessSnackbarMessage] = useState('');
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [errorSnackbarMessage, setErrorSnackbarMessage] = useState('');
  // State for
  const [initialOrganizationRows, setInitialOrganizationRows] = useState<
    IOrganizationRow[]
  >([]);
  const [updatedOrganizationRows, setUpdatedOrganizationRows] = useState<
    IOrganizationRow[]
  >([]);

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const { data, runQuery, loading, error } = useQuery(() =>
    getFeatureFlagAndEnablersById(id!)
  );

  const {
    data: organizations,
    loading: loadingOrganizations,
    error: errorOrganizations,
  } = useQuery(() => getOrganizations());

  // useEffect to set the initial state of the organization rows
  useEffect(() => {
    if (data && organizations) {
      const organizationRows: IOrganizationRow[] = organizations.map(
        (organization) => ({
          id: organization.id,
          enabled: data.organizations.reduce((acc, curr) => {
            return acc || curr.id === organization.id;
          }, false),
          name: organization.name,
        })
      );
      setInitialOrganizationRows(organizationRows);
      setUpdatedOrganizationRows(organizationRows);
    }
  }, [data, organizations]);

  if (error) {
    return <Typography color="red">{error.toString()}</Typography>;
  }
  if (errorOrganizations) {
    return <Typography color="red">{errorOrganizations.toString()}</Typography>;
  }
  if (loading || loadingOrganizations) {
    return <CircularProgress />;
  }
  if (data && organizations) {
    return (
      <>
        <SuccessSnackbar
          isOpen={openSuccessSnackbar}
          onClose={() => setOpenSuccessSnackbar(false)}
          message={successSnackbarMessage}
        />
        <ErrorSnackbar
          isOpen={openErrorSnackbar}
          onClose={() => setOpenErrorSnackbar(false)}
          message={errorSnackbarMessage}
        />
        <Container
          sx={{
            marginBottom: '1.5rem',
          }}
        >
          <div className="listViewHeaderContainer">
            <h1 className="listViewHeaderText">{formatId(id!)} Focus View</h1>
          </div>
          <div className="focusViewSectionContainer">
            <h2 className="focusViewSectionHeader">
              <strong>ID:</strong> {data.id}
            </h2>
            <h2 className="focusViewSectionHeader">
              <strong>Description:</strong> {data.description ?? 'n/a'}
            </h2>
            <h2 className="focusViewSectionHeader">
              <strong>Notes:</strong> {data.notes ?? 'n/a'}
            </h2>
          </div>
          <Box sx={{ width: '100%' }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              textColor="primary"
              indicatorColor="primary"
              aria-label="Tabs to switch between enablers for a feature flag"
            >
              <Tab label="Organizations" {...a11yProps(0)} />
              <Tab label="Users" {...a11yProps(1)} />
            </Tabs>
            <TabPanel value={tabValue} index={0}>
              <OrganizationTabPanel
                initialRows={initialOrganizationRows}
                setUpdatedRows={setUpdatedOrganizationRows}
                updatedRows={updatedOrganizationRows}
                runGetFeatureFlagAndEnablersByIdQuery={runQuery}
                setOpenSuccessSnackbar={setOpenSuccessSnackbar}
                setSuccessSnackbarMessage={setSuccessSnackbarMessage}
                setOpenErrorSnackbar={setOpenErrorSnackbar}
                setErrorSnackbarMessage={setErrorSnackbarMessage}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <UserTabPanel users={data.users} />
            </TabPanel>
          </Box>
        </Container>
      </>
    );
  }
  return <div>Something went wrong</div>;
};
