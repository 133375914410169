import { staffRoles } from '@api/user/types/IStaff';
import { FormControl, MenuItem, TextField } from '@mui/material';
import { Controller, FieldValues } from 'react-hook-form';
import { StaffFormInputProps } from './StaffFormInputProps';

export function StaffRoleDropdown<TFieldValues extends FieldValues>({
  name,
  control,
  styles,
}: StaffFormInputProps<TFieldValues>) {
  return (
    <FormControl style={styles} fullWidth>
      <Controller
        render={({ field: { onChange, value } }) => (
          <TextField
            id="outlined-textarea"
            select
            label="Role"
            placeholder="Role"
            multiline
            variant="outlined"
            value={value}
            onChange={onChange}
          >
            {staffRoles.map((role) => (
              <MenuItem key={role} value={role}>
                {role}
              </MenuItem>
            ))}
          </TextField>
        )}
        control={control}
        name={name}
      />
    </FormControl>
  );
}
