import { getFeatureFlagsAndEnablers } from '@api/featureFlags/getFeatureFlagsAndEnablers';
import { IFeatureFlag } from '@api/featureFlags/types/IFeatureFlags';
import { useQuery } from '@api/useQuery';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import {
  Button,
  Chip,
  CircularProgress,
  Container,
  Tooltip,
  Typography,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import './FeatureFlagsStyles.css';

export function FeatureFlagsListView() {
  const columns: GridColDef<IFeatureFlag>[] = [
    {
      field: 'view',
      headerName: '',
      width: 100,
      headerClassName: 'dataGridHeader',
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <Link
          to={`/feature-flags/${params.row.id}`}
          aria-label={`View feature flag ${params.row.id}`}
        >
          <Button startIcon={<FindInPageIcon />} className="editButton">
            View
          </Button>
        </Link>
      ),
    },
    {
      field: 'id',
      headerName: 'ID',
      width: 250,
      headerClassName: 'dataGridHeader',
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 1,
      headerClassName: 'dataGridHeader',
    },
    {
      field: 'organizations',
      headerName: 'Organizations',
      width: 150,
      type: 'number',
      headerClassName: 'dataGridHeader',
      sortComparator: (v1, v2) => {
        return v1.length - v2.length;
      },
      renderCell: (params) => (
        <Tooltip
          title={
            params.row.organizations.length > 0 ? (
              <>
                {params.row.organizations.map((organization) => (
                  <Chip
                    key={organization.id}
                    label={organization.name}
                    sx={{
                      margin: '0.1rem',
                      backgroundColor: '#f5f5f5',
                    }}
                  />
                ))}
              </>
            ) : (
              'No organizations'
            )
          }
          arrow
        >
          <span>{params.value.length as number}</span>
        </Tooltip>
      ),
    },
    {
      field: 'users',
      headerName: 'Users',
      width: 150,
      type: 'number',
      headerClassName: 'dataGridHeader',
      sortComparator: (v1, v2) => {
        return v1.length - v2.length;
      },
      renderCell: (params) => (
        <Tooltip
          title={
            params.row.users.length > 0 ? (
              <>
                {params.row.users.map((user) => (
                  <Chip
                    key={user.id}
                    label={user.firstName + ' ' + user.lastName}
                    sx={{
                      margin: '0.1rem',
                      backgroundColor: '#f5f5f5',
                    }}
                  />
                ))}
              </>
            ) : (
              'No users'
            )
          }
          arrow
        >
          <span>{params.value.length as number}</span>
        </Tooltip>
      ),
    },
  ];

  const { data, loading, error } = useQuery(() => getFeatureFlagsAndEnablers());

  if (error) {
    return <Typography color="red">{error.toString()}</Typography>;
  }
  if (loading || !data) {
    return <CircularProgress />;
  }
  return (
    <Container
      sx={{
        marginBottom: '1.5rem',
      }}
    >
      <div className="listViewHeaderContainer">
        <h1 className="listViewHeaderText">Feature Flags</h1>
      </div>
      <DataGrid
        rows={data}
        columns={columns}
        initialState={{
          pagination: { paginationModel: { pageSize: 25 } },
        }}
        sx={{
          backgroundColor: 'white',
        }}
        autoHeight
        pageSizeOptions={[25, 50, 100]}
      />
    </Container>
  );
}
