import { postLoginUser } from '@api/login/postLoginUser';
import { useLazyQuery } from '@api/useQuery';
import {
  GetCurrentUserApiReturn,
  getCurrentUser,
} from '@api/user/getCurrentUser';
import { CircularProgress, Container } from '@mui/material';
import { clearSession } from '@session/clearSession';
import { isUserLoggedIn } from '@session/isUserLoggedIn';
import { setJwt } from '@session/setJwt';
import { LoginPage } from 'pages/Login/LoginPage';
import { AuthContext, AuthContextReturn } from 'pages/Login/useAuthContext';
import { ReactNode, useCallback, useEffect, useState } from 'react';

type AuthContextProviderProps = {
  children: ReactNode;
};

/**
 * Ensures the app can only be displayed if the user is logged in.
 *
 * Handles the logic of:
 * 1. Loading the current user if they have a valid JWT.
 * 2. Displaying the login page if they do not have a valid JWT.
 */
export const AuthContextProvider = (props: AuthContextProviderProps) => {
  const { children } = props;

  // Handle all auth with the following:
  //
  // If the user has a valid JWT:
  // 1. Run the query to load the user.
  // 2. Display a spinner while this is loading
  // 3. Store their user record once the query is finished.
  // 4. Render the child component.
  //
  // If the user has no JWT:
  // 1. Send them to the login page.
  // 2. The login page will run a query to login the user and get a JWT.
  // 3. Once they have a JWT, run the query to load their user info.
  //
  // If the user has an expired or invalid JWT:
  // 1. We cannot tell if the JWT is invalid, only the server can. Run the query to load the current user with the invalid JWT.
  // 2. Once the server errors out, we clear out the JWT.
  // 3. Once the JWT is cleared out, the user will be sent to the login page automatically.
  const [currentUser, setCurrentUser] =
    useState<GetCurrentUserApiReturn | null>(null);

  const logoutUser = useCallback(() => {
    clearSession();
    setCurrentUser(null);
  }, []);

  const { error: getCurrentUserError, runQuery: runGetCurrentUserQuery } =
    useLazyQuery(getCurrentUser, {
      onError() {
        logoutUser();
      },
      onSuccess(data) {
        setCurrentUser(data);
      },
    });

  // If the user has a JWT when we first start the app, try and load the current user record.
  // If this fails, it means they are timed out so the query above will delete the JWT in local storage.
  useEffect(() => {
    if (isUserLoggedIn()) {
      runGetCurrentUserQuery();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { error: loginUserError, runQuery: runLoginQuery } = useLazyQuery(
    postLoginUser,
    {
      onError() {
        logoutUser();
      },
      onSuccess(data) {
        // This should not happen, but if we do not get a token for any reason than clear out the user/JWT.
        if (data?.token == null) {
          logoutUser();
          throw new Error(
            'Expected a token to be returned from the login API request.'
          );
        }

        setJwt(data.token);

        runGetCurrentUserQuery();
      },
    }
  );

  const loginUser = useCallback<AuthContextReturn['loginUser']>(
    async (username, password) => {
      // This query will handle errors and loading the current user's record from the API
      await runLoginQuery({
        email: username,
        password,
      });
    },
    [runLoginQuery]
  );

  const error = getCurrentUserError || loginUserError;

  // If we have no JWT in local storage, then just display the login page.
  if (!isUserLoggedIn() || error != null) {
    if (currentUser != null) {
      setCurrentUser(null);
    }
    return (
      <AuthContext.Provider
        value={{
          currentUser: null,
          logoutUser,
          loginUser,
        }}
      >
        <LoginPage error={error} />
      </AuthContext.Provider>
    );
  }

  if (currentUser == null) {
    return (
      <Container fixed>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        logoutUser,
        loginUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
